@use "../../styles/partials/resets";
@use "../../styles/partials/global";
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials//variables" as *;
@use "../../styles/partials/mixins" as *;

/* 
  Popup CSS Properties 
*/

.popup {
  background-color: rgba(0, 0, 0, 0.7);
  @include size(100%, 100%);
  position: fixed;
  top: 0;
  left: 0;
  @include flex(row, center, center);
  z-index: 1000;
  overflow: scroll;

  &__inner {
    background-color: #293949;
    @include size(100%, 100%);
    @include flex(column, space-between, center);
    @include tablet {
      border-radius: 10px;
      @include size(90%, auto);
    }
    @include desktop {
    }
  }

  &__content {
    @include flex(column, flex-start, normal);
    @include paddingTopBotRigLeft;
    @include tablet {
    }
    @include desktop {
    }
  }

  &__header {
    @include flex(row, flex-start, normal);
    @include paddingRigLeft;
    @include tablet {
    }
    @include desktop {
    }
  }

  &__container {
    @include flex(row, flex-start, normal);
    @include paddingTopBotRigLeft;
    @include tablet {
    }
    @include desktop {
    }
  }

  &__sub-header {
    margin-right: $padding-between-components-mobile;
    @include tablet {
      margin-right: $padding-between-components;
    }
    @include desktop {
    }
    &--modifier {
      @include paddingTopBotRigLeft;
      @include tablet {
      }
      @include desktop {
      }
    }
  }

  &__button-container {
    @include flex(row, flex-end, normal);
    @include paddingTopBotRigLeft;
    @include size(100%, auto);
    @include tablet {
      @include flex(row, flex-end, normal);
    }
    @include desktop {
    }
  }

  &__day-container {
    @include flex(row, flex-start, normal);
    @include tablet {
    }
    @include desktop {
    }
  }
}
