@use "./variables" as *;
@use "./breakpoints" as *;

/* Global CSS Properties */
h1 {
  font-family: $Avenir-Bold;
  font-size: $page-header-text-size-mobile;
  font-weight: $page-header-text-weight-mobile;
  line-height: $page-header-line-height-mobile;
  @include tablet {
    font-size: $page-header-text-size;
    font-weight: $page-header-text-weight;
    line-height: $page-header-line-height;
  }
  @include desktop {
  }
}

h2 {
  font-family: $Avenir-Bold;
  font-size: $section-header-text-size-mobile;
  font-weight: $section-header-text-weight-mobile;
  line-height: $section-header-line-height-mobile;
  @include tablet {
    font-size: $section-header-text-size;
    font-weight: $section-header-text-weight;
    line-height: $section-header-line-height;
  }
  @include desktop {
  }
}

h3 {
  font-family: $Avenir-Bold;
  font-size: $sub-header-text-size-mobile;
  font-weight: $sub-header-text-weight-mobile;
  line-height: $sub-header-line-height-mobile;
  @include tablet {
    font-size: $sub-header-text-size;
    font-weight: $sub-header-text-weight;
    line-height: $sub-header-line-height;
  }
  @include desktop {
  }
}

p,
a,
input,
textarea {
  text-decoration: none;
  font-family: $Avenir-Regular;
  font-size: $body-text-size-mobile;
  font-weight: $body-text-weight-mobile;
  line-height: $body-line-height-mobile;
  @include tablet {
    font-size: $body-text-size;
    font-weight: $body-text-weight;
    line-height: $body-line-height;
  }
  @include desktop {
  }
}

label,
button {
  font-family: $Avenir-Bold;
  font-size: $labels-buttons-text-size-mobile;
  font-weight: $labels-buttons-text-weight-mobile;
  line-height: $labels-buttons-line-height-mobile;
  @include tablet {
    font-size: $labels-buttons-text-size;
    font-weight: $labels-buttons-text-weight;
    line-height: $labels-buttons-line-height;
  }
  @include desktop {
  }
}

/*  */
