@use "../../styles/partials/resets";
@use "../../styles/partials/global";
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials//variables" as *;
@use "../../styles/partials/mixins" as *;

/* 
  Textbox CSS Properties 
*/

.textbox {
  @include flex(column, flex-start, normal);
  @include tablet {
  }
  @include desktop {
  }

  &__label {
    color: white;
    @include paddingRigLeft;
    @include tablet {
    }
    @include desktop {
    }
  }

  &__container {
    @include flex(row, flex-start, normal);
    @include paddingRigLeft;
    @include tablet {
    }
    @include desktop {
    }
  }

  // &__image-container {
  //   background-color: white;
  //   @include borderRadius(1px solid gray, 4px 0px 0 4px);
  //   border-right: none;
  //   @include flex(row, flex-start, normal);
  //   padding: $padding-between-components-mobile/2;
  //   @include tablet {
  //   }
  //   @include desktop {
  //   }
  // }

  // &__image {
  //   @include size(100%, 100%);
  //   @include tablet {
  //   }
  //   @include desktop {
  //   }
  // }

  &__text {
    background-color: white;
    color: black;
    resize: none;
    @include borderRadius(1px solid rgb(128, 128, 128), 20px);
    @include size(100%, 36px);
    @include paddingRigLeft;
    @include tablet {
    }
    @include desktop {
    }

    // &--error {
    //   @include borderRadius(1px solid #d22d2d, 0px 4px 4px 0px);
    //   border-left: none;
    // }
  }
}
