@use "../../styles/partials/resets";
@use "../../styles/partials/global";
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials//variables" as *;
@use "../../styles/partials/mixins" as *;

/*
    Header CSS Properties
*/

header {
  @include size(100%, 100%);
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");
@import url("//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.min.css");
body {
  color: white;
  background: #293949;
  font-family: "Open Sans", sans-serif;
  padding: 0;
  margin: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  position: relative;
}

.navbar-brand {
  color: white !important;
}

.nav-link {
  color: white !important;
}

.navbar-toggler {
  color: solid white !important;
  border: solid white !important;
  background-color: white !important;
}

header .bg-body-tertiary {
  background-color: #012b39 !important;
}
