@use "typography" as *;

/* Variables Properties */

// Desktop/Tablet Padding Properties
$padding-before-headlines: 3rem;
$padding-after-headlines: 1.5rem;
$padding-between-components: 1.5rem;

// Mobile Padding Properties
$padding-before-headlines-mobile: 1.5rem;
$padding-after-headlines-mobile: 1rem;
$padding-between-components-mobile: 1rem;

// Primary Colors
$primary-black: #323232;
$primary-blue: #0095ff;
$primary-silver: #afafaf;
$primary-white: #ffffff;

// Secondary Colors
$secondary-black: #000000;
$secondary-darkblue: #0065ad;
$secondary-mercury: #e1e1e1;
$secondary-alabaster: #fafafa;

// Desktop/Tablet Text Properties
$page-header-text-size: 2.0625rem;
$page-header-line-height: 2.75rem;
$page-header-text-weight: bold;
$section-header-text-size: 0.75rem;
$section-header-line-height: 1.25rem;
$section-header-text-weight: bold;
$sub-header-text-size: 0.875rem;
$sub-header-line-height: 1.375rem;
$sub-header-text-weight: bold;
$body-text-size: 0.875rem;
$body-line-height: 1.375rem;
$body-text-weight: 400;
$labels-buttons-text-size: 0.75rem;
$labels-buttons-line-height: 1.25rem;
$labels-buttons-text-weight: bold;

// Mobile Text Properties
$page-header-text-size-mobile: 1.5rem;
$page-header-line-height-mobile: 2rem;
$page-header-text-weight-mobile: bold;
$section-header-text-size-mobile: 0.75em;
$section-header-line-height-mobile: 1.25rem;
$section-header-text-weight-mobile: bold;
$sub-header-text-size-mobile: 0.8125rem;
$sub-header-line-height-mobile: 1.125rem;
$sub-header-text-weight-mobile: bold;
$body-text-size-mobile: 0.8125rem;
$body-line-height-mobile: 1.125rem;
$body-text-weight-mobile: 400;
$labels-buttons-text-size-mobile: 0.625rem;
$labels-buttons-line-height-mobile: 1.25rem;
$labels-buttons-text-weight-mobile: bold;

// Typography Properties
$Avenir-Regular: "Avenir";
$Avenir-Bold: "Avenir Bold";

// Desktop/Tablet Breakpoint Properties
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;
