/* Typography CSS Properties */

@font-face {
  font-family: "Avenir";
  src: url("../../assets/fonts/AvenirNextLTPro-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Bold";
  src: url("../../assets/fonts/AvenirNextLTPro-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: bold;
}
/*  */
