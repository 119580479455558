@use "../../styles/partials/resets";
@use "../../styles/partials/global";
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials//variables" as *;
@use "../../styles/partials/mixins" as *;

/* 
  Footer CSS Properties 
*/

footer {
  background-color: #012b39;
  @include size(100%, auto);
  position: fixed;
  bottom: 0px;
  z-index: 1030;
  @include flex(column, center, center);
  @include tablet {
  }
  @include desktop {
  }
}
