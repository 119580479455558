@use "../../styles/partials/resets";
@use "../../styles/partials/global";
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials//variables" as *;
@use "../../styles/partials/mixins" as *;

/* 
  Calendar CSS Properties 
*/

.Calendar-container {
}

.Calendar {
  @include tablet {
  }
  @include desktop {
  }
}

table.fc-scrollgrid {
  margin: 0;
}

.fc-view-harness {
  margin: $padding-between-components-mobile;
  @include tablet {
    margin: $padding-between-components;
  }
  @include desktop {
  }
}

table.fc-scrollgrid-sync-table {
  margin: 0;
}

table.fc-col-header {
  margin: 0;
}

.fc-header-toolbar {
  margin: $padding-between-components-mobile;
  @include tablet {
    margin: $padding-between-components;
  }
  @include desktop {
  }
}
