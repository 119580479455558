@use "../../styles/partials/resets";
@use "../../styles/partials/global";
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials//variables" as *;
@use "../../styles/partials/mixins" as *;

/* 
  Input CSS Properties 
*/

.field {
  @include flex(column, flex-start, normal);
  @include tablet {
  }
  @include desktop {
  }

  &__label {
    color: white;
    @include paddingRigLeft;
    @include tablet {
    }
    @include desktop {
    }
  }

  &__input {
    background-color: white;
    resize: none;
    @include borderRadius(1px solid gray, 20px);
    @include size(100%, 36px);
    @include paddingRigLeft;
    @include tablet {
    }
    @include desktop {
    }
  }
}
