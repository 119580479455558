@use "../../styles/partials/resets";
@use "../../styles/partials/global";
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials//variables" as *;
@use "../../styles/partials/mixins" as *;

/*
    Sign-up Page CSS Properties
*/

.signup-page {
  background: #105469;
  @include size(100%, auto);
  @include flex(column, flex-start, normal);
  @include tablet {
  }
  @include desktop {
  }

  &__sub-section {
    background-color: #012b39;
    border-radius: 10px;
    @include paddingTopBotRigLeft;
    @include flex(column, flex-start, normal);
    margin: $padding-between-components-mobile;
    @include tablet {
      margin: $padding-between-components;
    }
    @include desktop {
    }
  }

  &__sub-container {
    padding-right: $padding-between-components-mobile;
    @include tablet {
      padding-right: $padding-between-components;
    }
    @include desktop {
    }
    &--left {
      @include paddingZero;
      padding-left: $padding-between-components-mobile;
      @include tablet {
        padding-left: $padding-between-components;
      }
      @include desktop {
      }
    }
  }

  &__sub-header {
    font-size: 24px;
    @include tablet {
    }
    @include desktop {
    }
  }

  &__text {
    @include tablet {
    }
    @include desktop {
    }
  }

  &__img {
    width: 240px;
    @include tablet {
    }
    @include desktop {
      width: 360px;
    }
  }
}

.signup-page__sub-header {
  font-size: 24px;
  margin: $padding-between-components-mobile;
  @include tablet {
    margin: $padding-between-components;
  }
  @include desktop {
  }
}

.signup-page__form {
  margin: $padding-between-components-mobile;
  @include tablet {
    margin: $padding-between-components;
  }
  @include desktop {
  }
}

.signup__button {
  margin-top: $padding-between-components-mobile;
  @include tablet {
    margin-top: $padding-between-components;
  }
  @include desktop {
  }
}
