@use "../../styles/partials/resets";
@use "../../styles/partials/global";
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials//variables" as *;
@use "../../styles/partials/mixins" as *;

/*
    Project Page CSS Properties
*/

.project-page {
  background: #105469;
  @include size(100%, auto);
  position: absolute;
  top: 56px;
  padding-bottom: 38px;
  @include flex(column, flex-start, normal);
  @include tablet {
  }
  @include desktop {
  }

  &__header {
    margin-top: 60px;
  }

  &__sub-section {
    background-color: #012b39;
    border-radius: 10px;
    @include paddingTopBotRigLeft;
    @include flex(column, flex-start, normal);
    margin: $padding-between-components-mobile;
    @include tablet {
      margin: $padding-between-components;
    }
    @include desktop {
    }
  }

  &__sub-container {
    @include flex(row, space-between, normal);
    margin: $padding-between-components-mobile;
    @include tablet {
      margin: $padding-between-components;
    }
    @include desktop {
    }
  }

  &__sub-header {
    font-size: 24px;
    // margin: $padding-between-components-mobile;
    @include tablet {
      // margin: $padding-between-components;
    }
    @include desktop {
    }
  }
}

.project-page__sub-container .project-page__sub-header {
  margin: 0;
  @include tablet {
  }
  @include desktop {
  }
}
