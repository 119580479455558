@use "./typography";
@use "./breakpoints" as *;
@use "./variables" as *;

@mixin font($font, $size, $weight, $height) {
  font-family: $font;
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
}

@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

@mixin paddingTopBot {
  padding: $padding-between-components-mobile 0;
  @include tablet {
    padding: $padding-between-components 0;
  }
  @include desktop {
    padding: $padding-between-components 0;
  }
}

@mixin paddingRigLeft {
  padding: 0 $padding-between-components-mobile;
  @include tablet {
    padding: 0 $padding-between-components;
  }
  @include desktop {
    padding: 0 $padding-between-components;
  }
}

@mixin paddingTopBotRigLeft {
  padding: $padding-between-components-mobile;
  @include tablet {
    padding: $padding-between-components;
  }
  @include desktop {
    padding: $padding-between-components;
  }
}

@mixin paddingZero {
  padding: 0;
  @include tablet {
    padding: 0;
  }
  @include desktop {
    padding: 0;
  }
}

@mixin borderRadius($border, $radius) {
  border: $border;
  border-radius: $radius;
}

@mixin borderBox($top, $right, $bottom, $left) {
  border-top: $top;
  border-right: $right;
  border-bottom: $bottom;
  border-left: $left;
}

@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin maxWidth {
  max-width: $desktop-breakpoint;
}

@mixin background($image, $size, $position, $repeat) {
  background-image: $image;
  background-size: $size;
  background-position: $position;
  background-repeat: $repeat;
}
