@use "../../styles/partials/resets";
@use "../../styles/partials/global";
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials//variables" as *;
@use "../../styles/partials/mixins" as *;

/* 
  Buttons CSS Properties 
*/

.button {
  @include size(auto, auto);
  @include flex(row, flex-start, normal);
  @include tablet {
    max-width: 10rem;
  }
  @include desktop {
  }

  &__class {
    background-color: black;
    color: white;
    @include borderRadius(none, 20px);
    @include size(100%, 40px);
    @include paddingRigLeft;
    @include tablet {
    }
    @include desktop {
    }
  }
}
