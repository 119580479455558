@use "../../styles/partials/resets";
@use "../../styles/partials/global";
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials//variables" as *;
@use "../../styles/partials/mixins" as *;

/*
    Table Active Task CSS Properties
*/

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// body {
//   background: #105469;
//   font-family: "Open Sans", sans-serif;
// }
table {
  background: #012b39;
  border-radius: 0.25em;
  border-collapse: collapse;
  margin: $padding-between-components-mobile;
  @include tablet {
    margin: $padding-between-components;
  }
  @include desktop {
  }
}
th {
  border-bottom: 1px solid #364043;
  color: #e2b842;
  font-size: 0.85em;
  font-weight: 600;
  padding: 0.5em 1em;
  text-align: left;
}
thead {
  background: black;
}
td {
  color: #fff;
  font-weight: 400;
  padding: 0.65em 1em;
}
.disabled td {
  color: #4f5f64;
}
tbody tr {
  transition: background 0.25s ease;
}
tbody tr:hover {
  background: #014055;
}

.scrollit {
  overflow-x: scroll;
  margin-right: $padding-between-components-mobile;
}
