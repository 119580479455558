@use "../../styles/partials/resets";
@use "../../styles/partials/global";
@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials//variables" as *;
@use "../../styles/partials/mixins" as *;

/* 
  Chart CSS Properties 
*/

.Chart {
  width: 100%;
}
